import './SpecialOffers.css';

export default function SpecialOffers() {
    return (
        <div id="special-offers">
            <h1>Promocje</h1>
            <p>Bio:</p>
            <ol>
                <li>
                    Bioetanol bezzapachowy/cynamon z pomarańczą/świąteczny - 50 zł/5L
                </li>
                <li>
                    <a href="https://kratki.com/pl/biokominek-wolnostojacy-biomisa-czarny-certyfikat-tuv-7953">
                        Biomisa czarna z certyfikatem TUV powystawowy (używany) - 500 zł 
                    </a>
                </li>
            </ol>
            <p>Po więcej szczegółów zapraszam do kontaktu.</p>
        </div>
    )
}