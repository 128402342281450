import './App.css';
import Navbar from './components/Navbar';
import { Route, Routes } from 'react-router-dom';
import About from './pages/About';
import Gallery from './pages/Gallery';
import Offer from './pages/Offer';
import Contact from './pages/Contact';
import SpecialOffers from './pages/SpecialOffers';
import Menu from './components/Menu';
import BackgroundImage from './assets/sklejka_mini.jpg';
import Accordion from './components/NavbarMobile';
import Slider from './slider/ImageSlider';

function App() {
  return (
    <div id='all-content'> 
      <nav className='nav'>
          <div id='logo'>
              <img src={process.env.PUBLIC_URL + '/pictures/kominki-logo.png'} alt='logo'/>
          </div>
        <Navbar />
        <Accordion />
      </nav>
      <div className='desktop' id='best-fireplaces-image' style={{backgroundImage: `url(${BackgroundImage})`, backgroundSize: "100% 100%"}}>
        <div id='company-name'>
            <h1>ADAM KAROLCZAK</h1>
        </div>
      </div>
      <div className='mobile' id='best-fireplaces-carousel'>
        <Slider />
      </div>
      <div className='wrapper'>
        <div className="routes">
          <Routes>
            <Route path="/" element={<About />}/>
            <Route path="/adam-kominki" element={<About />}/>
            <Route path="/offer" element={<Offer />}/>
            <Route path="/gallery" element={<Gallery />}/>
            <Route path="/contact" element={<Contact />}/>
            <Route path="/special-offers" element={<SpecialOffers />}/>
          </Routes>
        </div>
        <Menu />
      </div>
    </div>
  );
}

export default App;
